import React, { useState } from 'react';
// import ProductContainer from '../components/ProductContainer';
import {AiFillStar} from 'react-icons/ai' ;
import {HiOutlineShoppingBag} from 'react-icons/hi';
import { Link } from 'react-router-dom';
// import TabPanel from './TabPanel' ;
import MyGallery from './features/GalleryImages';
import Modal from './features/Modal';
import {FaBlenderPhone} from 'react-icons/fa' ;
import {GiMoneyStack} from 'react-icons/gi';
import {FaShippingFast} from 'react-icons/fa' ;
import {BiTimer } from 'react-icons/bi' ;
import {FcCheckmark} from 'react-icons/fc';
import {CgCornerLeftDown} from 'react-icons/cg';
import {FcShipped} from 'react-icons/fc';
import TabPanel from './features/TabPanel';
import CurrencyInput from 'react-currency-input-field';



export default function Product() {


    const[click , setClick]= useState() ;


    const handleClick = () =>{

      setClick(!click);
    }

    return(
        <>

       <div className='container'>
           <div className="product-page">
               <section className='product-page-img'>
                    <MyGallery />
               </section>

               <section className="product-page-infos animate__animated animate__fadeIn">
                   <h3>COCTUM | WIFI multi-function Food Processor</h3>
                   <h6 style={{'display':'flex','flexDirection':'column'}}><span><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar />  </span>  (18 reviews)</h6>
                   <h2>4 500 MAD </h2>
                   <span className='text-light'>Les frais de livraison sont inclus  <FcShipped /></span>
                   <p>Coctum est un robot cuiseur multifonction compact et facile à utiliser, tout-en-un promet de réaliser toutes sortes de plats en peu de temps avec un minimum d’effort. Plusieurs recettes guidées pas à pas, sont intégrées dans l’appareil et d’autres peuvent se télécharger via connexion Wi-Fi.</p>
                   <section style={{'display':'flex','alignItems':'flex-start','justifyContent':'space-between'}}><span style={{'color':'white','fontSize':'12px','border':'1px solid #ffffff8f','border-radius':'5px','padding':'1px 5px'}}><FcCheckmark /> En Stock</span>  <button  onClick={handleClick} style={{'display':'flex','flexDirection':'column','border':'1px solid #ffffff91','borderRadius':'5px','padding':'2px 10px','background':'transparent','margin-bottom':'2px','color':'white'}}><span >{click ? <CgCornerLeftDown className='animate__animated animate__fadeInUp'/> : '+'} livraison <FaShippingFast /></span></button></section>
                   <section className='animate__animated animate__fadeInDown' style={{display: click ? 'flex' : 'none' ,'flexDirection':'column','border':'1px solid #ffffff91','borderRadius':'5px','padding':'5px 14px','marginBottom':'5px'}}>
                   <span className='animate__animated animate__fadeInUp' style={{'color':'white','fontSize':'12px'}}> - Livraison Gratuit au <strong>Maroc </strong>- 48h <BiTimer /></span>
                   <span className='animate__animated animate__fadeInUp' style={{'color':'white','fontSize':'12px'}}> - Livraison à 95€ <strong> Europ - Pays arabes</strong> : via ARAMEX ~ 5j <BiTimer /></span>
                   <span className='animate__animated animate__fadeInUp' style={{'color':'white','fontSize':'12px'}}> - Livraison à 110€ <strong> Canada - Etats-unis</strong> : via ARAMEX ~ 5j <BiTimer /></span>
                   </section>
                   <p><a className='link-call' href="tel:+212 536 68 67 45">Commander par Telephone <FaBlenderPhone /></a></p>
                   <Link to="/formulaire" >ACHETER MAINTENANT <span style={{'fontSize':'18px'}}><HiOutlineShoppingBag /></span></Link>
               </section>
           </div>
           <div className="product-page-desc">
           <TabPanel />
           
           </div>

          


           </div>      
        </>
    );
}